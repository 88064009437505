<template>
	<Metier name="qse" title="Qualité Sécurité Environnement" :main-menu="[{title: 'Contremaître principal MQS', missions: ['Participe à la préparation et au suivi des audits QSEH du site', 'Participe à la rédaction et à la mise à jour des manuels de management qualité, sécurité, environnement', 'Est chargé de la mise en place du référentiel de management du Groupe au sein du site', 'Gère le logiciel interne de reporting d\'accidents'], competences: ['Formation aux systèmes de management et aux audits QSEH', 'Expérience significative dans le raffinage', 'Connaissance de l\'organisation et des installations du site', 'Maîtrise de l\'informatique'], qualites: ['Leadership', 'Rigueur', 'Capacité d\'adaptation', 'Sens de l\'initiative']}, {title: 'Agent de maitrise management QSEH', missions: ['Collabore avec les différents services à la mise en place d\'analyse QSEH', 'S\'assure de la conformité des procédures avec la réglementation et les directives du Groupe', 'Participe à la préparation et au suivi des audits QSEH du site', 'Anime des modules de formation QSEH'], competences: ['Formation aux systèmes de management et aux audits QSEH', 'Expérience significative dans le raffinage', 'Connaissance de l\'organisation et des installations du site', 'Maîtrise de l\'informatique'], qualites: ['Rigueur', 'Capacité d\'adaptation', 'Sens de l\'initiative', 'Esprit d\'analyse, de synthèse']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
